import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

export const FileListComponent = () => {
  const [fileItems, setFileItems] = useState([
    {
      id: '1555',
      name: 'Gihan Testing',
    },
    {
      id: '2555',
      name: 'Test User 2',
    },
    {
      id: '3555',
      name: 'Test User 3',
    },
  ]);

  const columns: any = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: any) => row?.name,
        sortable: true,
      },
      {
        name: '',
        selector: (row: any) => {
          return <Link to={`q/${row?.id}`}>View</Link>;
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      style: {
        fontSize: '16px',
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-5">
          <div className="table_outer_div">
            <DataTable
              title="Sample List"
              data={fileItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              //   progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
