import { Alert, Button, Input, Space, Typography } from 'antd';
import { useState } from 'react';
import { checkAnswer } from '../api/uploadFilesAPI';
import { openNotification } from '../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../const/notificationTypes';

const { TextArea } = Input;
const { Title } = Typography;

export const QuestionComponent = ({ qId, isFileHave }: any) => {
  const [inputText, setInputText] = useState('');
  const [answerText, setAnswerText] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);

  const failSubmission = (message: any) => {
    setSubmitLoading(false);
    openNotification({ message });
  };

  const successSubmission = (data: any) => {
    setSubmitLoading(false);

    if (data) {
      setAnswerText(data);
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Something went wrong' });
    }
  };

  const answerButtonClick = async () => {
    if (inputText !== '') {
      setAnswerText('');
      setInputText('');
      setSubmitLoading(true);
      checkAnswer({ id: qId, message: inputText }).then((data) => {
        try {
          if (data['res']) {
            successSubmission(data['res']);
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'No Text Provided' });
    }
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(e, 'I was closed.');
  };

  return (
    <>
      {isFileHave ? (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mt-5">
              <Title level={4}>What you need to know from the document?</Title>
              <TextArea
                rows={6}
                placeholder="maxLength is 6"
                maxLength={150}
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                disabled={submitLoading}
              />
            </div>
            <div className="col-sm-12 mt-2" style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => answerButtonClick()} loading={submitLoading}>
                Check Answer
              </Button>
            </div>
          </div>
          {answerText !== '' && (
            <div className="col-sm-12 mt-5">
              <Title level={4}>Answer</Title>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Alert message={answerText} type="warning" closable onClose={onClose} />
              </Space>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
