import { useEffect, useMemo, useState } from 'react';
import { deleteFile, modelUpdateApi, readAllFiles, uploadFileApi } from '../api/uploadFilesAPI';
import DataTable from 'react-data-table-component';
import { Button, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { openNotification } from '../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../const/notificationTypes';

export const UploadedFileListComponent = ({ qId, setIsFileHave }: any) => {
  const [fileItems, setFileItems] = useState([]);
  const [pending, setPending] = useState(true);

  const [tableTrigger, setTableTrigger] = useState(true);

  useEffect(() => {
    if (qId) {
      readAllFiles({ id: qId }).then((data) => {
        try {
          if (data['res']) {
            console.log(data['res']);
            if (data['res']?.length > 0) {
              setFileItems(data['res']);
              setIsFileHave(true);
              setPending(false);
            } else {
              setFileItems([]);
              setIsFileHave(false);
              setPending(false);
            }
          } else {
            setFileItems([]);
            setIsFileHave(false);
            setPending(false);
          }
        } catch (error) {
          setFileItems([]);
          setIsFileHave(false);
          setPending(false);
        }
      });
    }
  }, [qId, tableTrigger]);

  const failSubmission = (message: any) => {
    setPending(false);
    openNotification({ message });
  };

  const deleteFileClick = (row: any) => {
    if (row[0]) {
      setPending(true);
      deleteFile({ id: qId, fileId: row[0] }).then((data) => {
        try {
          if (data['res']) {
            window.location.reload();
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'No File Provided' });
    }
  };

  const columns: any = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row: any) => row[0],
        sortable: true,
        grow: 1,
      },
      {
        name: 'File Name',
        selector: (row: any) => row[1],
        sortable: true,
        grow: 2,
      },
      {
        name: '',
        selector: (row: any) => {
          return (
            <Button type="primary" onClick={() => deleteFileClick(row)} danger>
              Delete
            </Button>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      style: {
        fontSize: '16px',
      },
    },
  };

  const uploadFile = async (file: any, fileName: string) => {
    if (file) {
      setPending(true);
      uploadFileApi({ id: qId, file: file }).then((data) => {
        try {
          if (data['message']) {
            setTableTrigger(!tableTrigger);
            setPending(false);
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'No File Provided' });
    }
  };

  const generateButtonClick = async () => {
    if (qId !== '') {
      setPending(true);
      modelUpdateApi({ id: qId }).then((data) => {
        try {
          if (data['res']) {
            openNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Model updated' });
            setPending(false);
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'No Text Provided' });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 mt-5" style={{ textAlign: 'right' }}>
          <Upload
            disabled={pending}
            // accept="video/mp4"
            customRequest={({ file, onError, onSuccess, onProgress }: any) => {
              const fileName = file.name;

              //API END
              uploadFile(file, fileName);
            }}
            multiple={false}
          >
            <Button loading={pending}>Upload Sample File</Button>
          </Upload>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-2">
          <div className="table_outer_div">
            <DataTable
              title="Uploaded Files"
              data={fileItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      {fileItems?.length > 0 && (
        <div className="col-sm-12 mt-2" style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => generateButtonClick()} loading={pending}>
            Generate Model
          </Button>
        </div>
      )}
    </div>
  );
};
