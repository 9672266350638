import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { FileListComponent } from './pages/FileListComponent';
import { QuestionAnswerSectionComponent } from './pages/QuestionAnswerSectionComponent';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* <Route
          path="/"
          element={
            <Protected>
              <>HOME</>
            </Protected>
          }
        />
        <Route
          path="/user"
          element={
            <Protected>
              <AllUsers />
            </Protected>
          }
        />

        <Route path="/login" element={<Login />} /> */}

        <Route path="/" element={<FileListComponent />} />
        {/* <Route path="/files" element={<UploadedFileListComponent />} /> */}
        <Route path="/q/:qId" element={<QuestionAnswerSectionComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
