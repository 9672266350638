import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UploadedFileListComponent } from './UploadedFileListComponent';
import { QuestionComponent } from './QuestionComponent';

export const QuestionAnswerSectionComponent = () => {
  const { qId } = useParams();
  const [isFileHave, setIsFileHave] = useState(false);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12"></div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12">
          <Link to={`/`}>Back</Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-7">
          <UploadedFileListComponent qId={qId} setIsFileHave={setIsFileHave} />
        </div>
        <div className="col-sm-5">
          <QuestionComponent qId={qId} isFileHave={isFileHave} />
        </div>
      </div>
    </div>
  );
};
